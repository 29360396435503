export const NatureOfEngagementWorkx = [
  "Freelancer",
  "Full time employee",
  "On-Contract",
  "Part -time consultant",
  "Founder",
  "Advisor",
  "Board member"
];
export const SessionDelivered = [
  "20+",
  "50+",
  "100+",
  "250+",
  "500+",
  "750+",
  "1000+"
];
export const totalNumberCleintsTraining = [
  "20+",
  "50+",
  "100+",
  "150+",
  "250+",
  "400+",
  "750+"
];
// 20+,50+,100+,150+,250+,400+,750+
export const Departments = [
  "Finance",
  "Sales",
  "Marketing",
  "Digital Marketing",
  "Operations",
  "Supply Chain",
  "Human Resources",
  "Learning and Development",
  "Customer Service",
  "Procurement",
  "Production",
  "Legal",
  "Product Management",
  "Quality",
  "Facility Management",
  "Software Testing",
  "Mobile App Development",
  "Web Application Development: Back-end Engineering",
  "Web Application Development: Front-end Engineering",
  "Desktop Application Development",
  "Middleware Development",
  "Firmware Development",
  "IT Administration",
  "Networking-Security- Cloud Infrastructure",
  "Data Engineering",
  "Business Intelligence",
  "Data Science and AI",
  "Embedded Engineering",
  "UI/UX or Design",
  "Product Management"
];
export const Industry = [
  "IT",
  "Hospitality",
  "Real Estate",
  "Retail",
  "Logistics",
  "Manufacturing",
  "Education",
  "ITES",
  "Customer service",
  "Wellness",
  "Research",
  "BFSI",
  "Automobiles",
  "Ecommerce",
  "FMCG",
  "Media",
  "Entertainment",
  "Advertisement",
  "Healthcare",
  "Telecommunication",
  "Aviation Industry",
  "Energy"
];
export const methodology = [
  "Theater",
"Storytelling",
"Yoga",
"Music",
"Outbound",
"In-bound",
"Experiential",
"Game-based",
"Case-study based",
"Business Simualtion (No-Tool)",
"Simulation (Software Tool)",
"VR/AR based",
"Hands-on",
"Code-walk-through | Demo",
"Theoretical or Lecture-based",
"Software-Tool based", 
"Project-based"
]
export const CourseComplexity = [
  "Beginner",
  "Intermediate",
  "Advanced",
  "Complete/Full Course"
];
export const TargetAuinceCourseOutline = [
  "Freshers",
  "Individual contributor",
  "Team Lead",
  "First Line Management",
  "Middle Management",
  "Senior or Top Management",
  "CXO"
];
export const CleintList = [
  "Embitel",
  "Zinavo",
  "Tata Consultancy Services",
  "Accenture",
  "Tech Mahindra",
  "Infosys",
  "StableBow",
  "Storywallahs",
  "The Painted Sky",
  "Mu Sigma",
  "HTMT Global Solutions",
  "Next Education",
  "Royal Bank of Scotland",
  "Morgan Stanley",
  "AwesomeStats Consulting",
  "Indian Institute of Management Bangalore",
  "Hopoye Technologies",
  "Arima Research",
  "Deloitte & Touche",
  "Nokia Siemens Networks",
  "Satyam Computer Services",
  "Einext",
  "General Electric (GE)",
  "LinkedIn Corp",
  "CitiusTech",
  "Xperi Corporation",
  "Emids",
  "Neebal Learning",
  "Neebal Technology",
  "Supercoders",
  "Proxim Wireless",
  "Aadee Neo Consulting",
  "Dale Carnegie Training India",
  "Taxi For Sure",
  "JP Morgan Chase",
  "Capgemini",
  "WNS Global Services",
  "Dell",
  "Convergys",
  "EXL Services",
  "GE Capital",
  "McDonald's India",
  "Volvo",
  "CIBER, Inc.",
  "HCL Technologies",
  "Hewlett-Packard",
  "Trigyn Technologies",
  "PricewaterhouseCoopers",
  "Prestige University",
  "Indian Institute of Management, Indore",
  "John Deere",
  "TATA Consultancy Services",
  "Indira Securities",
  "Swastika Ltd",
  "Defour Analytics",
  "Piramal Health Care Ltd",
  "Invesco Ltd,",
  "Cyient",
  "CGI Inc.",
  "Wells Fargo",
  "Verizon",
  "ESN Technologies",
  "Silicon Interfaces",
  "Jay Instruments",
  "Firmware International Pvt. Ltd,",
  "ISRO",
  "DRDO",
  "Intel",
  "CISCO",
  "Samsung",
  "Motorola",
  "Ericsson",
  "L&T",
  "Accenture",
  "EMC2",
  "Emerson",
  "Honeywell",
  "Vodafone",
  "Tata Elxsi",
  "Cognizant",
  "Tech Mahindra",
  "PATNI COMPUTER SYSTEMS LTD",
  "PATNI COMPUTER SYSTEMS LTD",
  "Freelancer/Self-Employed",
  "BMC",
  "CDAC",
  "JM Financial",
  "Jardine Lloyd Thompson Pvt. Ltd. (MMC)",
  "Utkarsha Bank",
  "Flipkart",
  "Genpact",
  "Deloitte",
  "Star Union Dai-Ichi Life Insurance Company Limited",
  "Continuum",
  "JB BODA Group",
  "DBS Bank",
  "Syndrome Technologies",
  "eACT Technologies",
  "Sapura Thales Electronics",
  "DSO National Laboratories",
  "Axis Bank",
  "American International Group",
  "J.C. Penney",
  "Citibank",
  "BMW",
  "Hindustan Unilever"
];
